<template>
  <div class="rsvi-tabs" :ref="`rsvi-tabs_${_uid}`">
    <div class="rsvi-tabs__head">
      <div
        class="rsvi-tabs__item"
        :class="{ 'rsvi-tabs__item_active': activeTab === 0 }"
        @click="choiceTab(0)"
      >
        <svg
          class="mr-2 mb-sm-2"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="19.5" stroke="#00548B" />
          <path
            d="M16.6482 17.8479C16.8956 17.8479 17.0962 17.6473 17.0962 17.3999C17.0962 17.1525 16.8956 16.9519 16.6482 16.9519C16.4008 16.9519 16.2002 17.1525 16.2002 17.3999C16.2002 17.6473 16.4008 17.8479 16.6482 17.8479Z"
            stroke="#00548B"
            stroke-width="1.2"
            stroke-linecap="round"
          />
          <path
            d="M23.3443 17.8479C23.5917 17.8479 23.7923 17.6473 23.7923 17.3999C23.7923 17.1525 23.5917 16.9519 23.3443 16.9519C23.0968 16.9519 22.8963 17.1525 22.8963 17.3999C22.8963 17.6473 23.0968 17.8479 23.3443 17.8479Z"
            stroke="#00548B"
            stroke-width="1.2"
            stroke-linecap="round"
          />
          <circle cx="20" cy="20" r="10.7" stroke="#00548B" />
          <path
            d="M24.3402 22.6519C23.8649 23.4477 23.1936 24.1085 22.3905 24.5713C21.5874 25.0342 20.6791 25.2836 19.7522 25.2959C18.8468 25.2851 17.9586 25.0472 17.1689 24.6042C16.3792 24.1611 15.7133 23.527 15.2322 22.7599"
            stroke="#00548B"
            stroke-linecap="round"
          />
          <path
            d="M18.8003 13.6L18.5003 13.9999L18.8003 13.6ZM21.9538 13.9535C22.149 13.7582 22.149 13.4416 21.9537 13.2464C21.7584 13.0511 21.4418 13.0512 21.2466 13.2464L21.9538 13.9535ZM20.2533 8.72206C19.2692 9.02486 18.2416 9.82035 17.7337 10.7868C17.4752 11.2788 17.3366 11.8449 17.4442 12.4255C17.5532 13.0135 17.9027 13.5517 18.5003 13.9999L19.1003 13.2C18.6659 12.8742 18.4828 12.542 18.4275 12.2433C18.3708 11.9371 18.4362 11.5998 18.619 11.252C18.9937 10.5389 19.7981 9.90838 20.5474 9.67784L20.2533 8.72206ZM18.5003 13.9999C19.4157 14.6866 20.2408 14.7592 20.8832 14.5819C21.4952 14.4131 21.8711 14.0362 21.9538 13.9535L21.2466 13.2464C21.196 13.2971 20.9718 13.5201 20.6172 13.618C20.2929 13.7074 19.7848 13.7133 19.1003 13.2L18.5003 13.9999Z"
            fill="#00548B"
          />
        </svg>
        Недоношенные дети
      </div>
      <div
        class="rsvi-tabs__item"
        :class="{ 'rsvi-tabs__item_active': activeTab === 1 }"
        @click="choiceTab(1)"
      >
        <svg
          class="mr-2 mb-sm-2"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="19.5" stroke="#00548B" />
          <path
            d="M14.3021 23.5181C14.4974 23.7133 14.814 23.7133 15.0092 23.5181C15.2045 23.3228 15.2045 23.0062 15.0092 22.811L14.3021 23.5181ZM13.1203 22.3363L14.3021 23.5181L15.0092 22.811L13.8274 21.6291L13.1203 22.3363Z"
            fill="#00548B"
          />
          <path
            d="M12.1878 23.9109C12.1648 24.1861 12.3693 24.4277 12.6445 24.4507C12.9197 24.4736 13.1614 24.2691 13.1843 23.9939L12.1878 23.9109ZM12.7393 19.0015C12.6158 18.7545 12.3155 18.6544 12.0685 18.7779C11.8215 18.9014 11.7214 19.2017 11.8449 19.4487L12.7393 19.0015ZM26.3704 24.0021C26.3978 24.2769 26.6429 24.4774 26.9176 24.4499C27.1924 24.4224 27.3929 24.1774 27.3654 23.9026L26.3704 24.0021ZM19.6709 10.1645V18.4372H20.6709V10.1645H19.6709ZM20.1157 17.9403C18.823 18.0839 16.8301 18.572 15.1658 19.586L15.6862 20.44C17.1922 19.5223 19.034 19.0666 20.2261 18.9342L20.1157 17.9403ZM15.1658 19.586C13.6607 20.5031 12.3556 21.8974 12.1878 23.9109L13.1843 23.9939C13.3155 22.4195 14.3304 21.2661 15.6862 20.44L15.1658 19.586ZM15.426 19.513C14.9902 19.513 14.3179 19.4749 13.7269 19.364C13.4302 19.3083 13.1748 19.2381 12.9861 19.1558C12.7795 19.0657 12.7376 18.9981 12.7393 19.0015L11.8449 19.4487C12.0042 19.7673 12.3187 19.9557 12.5865 20.0725C12.8722 20.197 13.2094 20.2843 13.5425 20.3468C14.2114 20.4723 14.9485 20.513 15.426 20.513V19.513ZM20.1089 18.9334C21.0674 19.0532 22.5876 19.609 23.9041 20.5223C25.2278 21.4407 26.2342 22.6409 26.3704 24.0021L27.3654 23.9026C27.1864 22.1124 25.8948 20.6863 24.4741 19.7007C23.0461 18.71 21.3754 18.0839 20.2329 17.9411L20.1089 18.9334Z"
            fill="#00548B"
          />
          <path
            d="M27.7091 19.0548C27.8326 18.8078 27.7324 18.5075 27.4854 18.384C27.2385 18.2605 26.9381 18.3606 26.8146 18.6076L27.7091 19.0548ZM23.2672 20.116C23.9154 20.188 24.8695 20.1439 25.7003 20.0038C26.1173 19.9334 26.5256 19.8352 26.8611 19.7046C27.0288 19.6392 27.1921 19.5605 27.3331 19.4639C27.4702 19.37 27.6174 19.2381 27.7091 19.0548L26.8146 18.6076C26.8275 18.5819 26.8294 18.5968 26.7681 18.6388C26.7105 18.6782 26.6221 18.7244 26.498 18.7728C26.25 18.8694 25.9148 18.9534 25.534 19.0177C24.7693 19.1467 23.9113 19.1814 23.3777 19.1221L23.2672 20.116Z"
            fill="#00548B"
          />
          <path
            d="M26.0803 22.3767L25.2924 23.9524"
            stroke="#00548B"
            stroke-linecap="round"
          />
          <path
            d="M9.53484 27.4979C7.56514 26.71 7.39784 23.9187 8.35302 19.6191C9.05755 16.4478 11.6041 14.6184 13.3056 13.9131C13.6742 13.7603 14.0704 13.6539 14.4668 13.6993C16.7999 13.9666 17.8371 17.9174 18.2013 21.1949C18.3023 22.1036 18.2172 22.9154 18.055 23.6248C17.5892 25.6618 16.002 27.3716 13.9333 27.666C12.3518 27.891 10.5657 27.9102 9.53484 27.4979Z"
            stroke="#00548B"
            stroke-linecap="round"
          />
          <path
            d="M30.5011 27.1705C32.4303 26.3803 32.5942 23.5805 31.6587 19.268C30.9716 16.1009 28.496 14.2682 26.8295 13.5541C26.4548 13.3935 26.0497 13.2811 25.6454 13.3335C23.3779 13.6276 22.3681 17.5732 22.0127 20.8485C21.9112 21.7837 22.0015 22.6164 22.1685 23.341C22.6305 25.3457 24.1616 27.0437 26.1974 27.3397C27.7452 27.5648 29.4924 27.5837 30.5011 27.1705Z"
            stroke="#00548B"
            stroke-linecap="round"
          />
        </svg>
        Бронхолегочная дисплазия (БЛД)
      </div>
      <div
        class="rsvi-tabs__item"
        :class="{ 'rsvi-tabs__item_active': activeTab === 2 }"
        @click="choiceTab(2)"
      >
        <svg
          class="mr-2 mb-sm-2"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="19.5" stroke="#00548B" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.6666 9.61128C29.2353 9.59169 32.2777 11.377 32.2777 17.1475C32.2777 22.918 19.9998 31.2778 19.9998 31.2778C19.9998 31.2778 7.72211 22.918 7.72211 17.1475C7.72211 11.377 10.7644 9.61491 14.3332 9.61128C16.2221 9.60936 18.821 10.7931 20 12.4373C21.1787 10.7931 23.7565 9.62177 25.6666 9.61128Z"
            stroke="#00548B"
            stroke-linecap="square"
          />
          <path
            d="M10.6111 19.3611H15.8773L16.6296 22.9722L18.8865 13.9444L21.5196 26.2222L24.1528 17.1944L24.9051 19.3611H28.6666"
            stroke="#00548B"
            stroke-linecap="round"
          />
        </svg>
        Врожденный порок сердца (ВПС)
      </div>
    </div>
    <div class="rsvi-tabs__content">
      <div v-if="activeTab === 0">
        <div class="text-1 mb-4">
          Каждый год в мире приблизительно 15 миллионов детей рождаются
          недоношенными<sup>[8]</sup>. Высокий риск тяжелого течения РСВИ у
          недоношенных детей связан с <sup>[9-11]</sup>:
        </div>
        <ul>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >недоразвитыми уязвимыми дыхательными путями, так как легкие
              формируются на поздних этапах внутриутробного развития
              ребенка;</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >неполной передачей материнских антител, так как пик передачи
              приходится на последний триместр беременности.</span
            >
          </li>
        </ul>
        <div class="text-1 mb-4">
          <strong>Развитие легких<sup>[12]</sup></strong>
        </div>
        <div class="simple-carousel__container">
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide1(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide1(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide1.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide1.jpg"
            />
          </picture>
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide2(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide2(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide2.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide2.jpg"
            />
          </picture>
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide3(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide3(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide3.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide3.jpg"
            />
          </picture>
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide4(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide4(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide4.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/slide4.jpg"
            />
          </picture>
        </div>
        <div class="text-1 mb-4 mt-8">
          <strong
            >На сроке в ~ 30 недель гестации объём лёгких у недоношенных детей
            примерно в 3 раза меньше, чем у рождённых в срок<sup
              >[12,13]</sup
            ></strong
          >
        </div>
        <div class="text-1 mb-4">
          Недоношенность может принципиально менять течение РСВИ, что может
          приводить к<sup>[12]</sup>:
        </div>
        <ul>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >более длительным госпитализациям;</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >необходимости кислородной поддержки;</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >переводу в отделение реанимации и интенстивной терапии;</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242">искусственной вентиляции легких.</span>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 1">
        <div class="text-1 mb-4">
          <strong>Развитие БЛД тесно связано с недоношенностью</strong>,
          поскольку развитие легких происходит на позднем этапе внутриутробного
          формирования организма ребенка<sup>[14,15]</sup>.
        </div>
        <ul>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >У детей с БЛД отмечается недостаточная функция дыхательных путей,
              недостаточное развитие и повреждение альвеол, что может повышать
              потребность в респираторной поддержке при тяжелом течении острой
              инфекции нижних дыхательных путей (ИНДП)<sup>[9-11]</sup>.</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >Развитие БЛД или других хронических респираторных заболеваний
              повышает риск тяжелого течения инфекций в связи с ограничением
              функционального резерва легких, нарушением архитектуры дыхательных
              путей и преобладанием противовоспалительного
              окружения<sup>[9-11]</sup>.</span
            >
          </li>
        </ul>
        <picture>
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-1(mob).jpg
            "
            media="(max-width: 767px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-1(tab).jpg
            "
            media="(max-width: 1199px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-1.jpg
            "
            media="(min-width: 1299px)"
          />
          <img
            class="mb-4"
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-1.jpg"
          />
        </picture>
        <a href="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/pfd/2022/12/21/%D0%91%D1%80%D0%BE%D0%BD%D1%85%D0%BE%D0%BB%D0%B5%D0%B3%D0%BE%D1%87%D0%BD%D0%B0%D1%8F_%D0%B4%D0%B8%D1%81%D0%BF%D0%BB%D0%B0%D0%B7%D0%B8%D1%8F_%D0%91%D0%9B%D0%94_%D0%B8_%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D0%A0%D0%A1%D0%92_%D0%B8%D0%BD%D1%84%D0%B5%D0%BA%D1%86%D0%B8%D0%B8.pdf?_sm_au_=iVVDFS0J40pQsFq6pGsWvKttvN1NG" target="_blank">
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-2(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-2(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-2.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-2.jpg"
            />
          </picture>
        </a>
      </div>
      <div v-if="activeTab === 2">
        <div class="text-1 mb-4">
          <strong
            >Дети с гемодинамически значимыми ВПС (гзВПС) – еще одна группа
            пациентов с повышенным риском осложненного клинического течения
            РСВИ, связанного с изменениями в работе легких или легочном
            кровотоке, ведущих к усилению вентиляционно-перфузионного дисбаланса
            и гипоксии<sup>[30,31]</sup>.</strong
          >
        </div>
        <div class="text-1 mb-4">
          <strong
            >Наиболее распространенными формами пороков являются внутрисердечные
            шунты<sup>[32]</sup></strong
          >
        </div>
        <video
          class="mb-4"
          autoplay
          muted
          loop
          src="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/video1.mp4
              "
        ></video>
        <ul>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >Как следствие, у детей с ВПС инфекции нижних дыхательных путей
              (ИНДП) приводят к развитию дыхательной и/или сердечной
              недостаточности<sup>[16,17]</sup>.</span
            >
          </li>
          <li style="color: #ff547c">
            <span style="color: #3c4242"
              >При госпитализации по поводу РСВИ у таких детей отмечается более
              тяжелое клиническое течение заболевания, а именно, чаще отмечается
              потребность во вспомогательной вентиляции легких и в более
              длительной кислородной поддержке<sup>[16,17]</sup>.</span
            >
          </li>
        </ul>

        <a href="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/pfd/2022/12/21/%D0%B2%D1%80%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D1%80%D0%BE%D0%BA%D0%B8_%D1%81%D0%B5%D1%80%D0%B4%D1%86%D0%B0_%D0%B8_%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D1%80%D1%81%D0%B2-%D0%B8%D0%BD%D1%84%D0%B5%D0%BA%D1%86%D0%B8%D0%B8.pdf?_sm_au_=iVVDFS0J40pQsFq6pGsWvKttvN1NG" target="_blank">
          <picture>
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-3(mob).jpg
              "
              media="(max-width: 767px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-3(tab).jpg
              "
              media="(max-width: 1199px)"
            />
            <source
              srcset="
                https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-3.jpg
              "
              media="(min-width: 1299px)"
            />
            <img
              class="mt-4"
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/rsvi/tab-3.jpg"
            />
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleCarousel from "@/components/pageComponents/SimpleCarousel.vue";
import Vue from "vue";
export default {
  name: "RsviTabs",
  data: () => ({
    activeTab: 0,
  }),
  methods: {
    choiceTab(ind) {
      this.activeTab = ind;
      setTimeout(() => {
        this.initSlider();
      }, 100);
    },
    initSlider() {
      const vm = this;
      if (
        this.$refs[`rsvi-tabs_${this._uid}`] &&
        this.$refs[`rsvi-tabs_${this._uid}`].querySelector(
          ".simple-carousel__container"
        )
      ) {
        this.$refs[`rsvi-tabs_${this._uid}`]
          .querySelectorAll(".simple-carousel__container:not(.loaded)")
          .forEach((slider) => {
            let ComponentClass = Vue.extend(SimpleCarousel);
            let instance = new ComponentClass({
              propsData: {
                Slides: Array.from(slider.children),
                color: "#00548B",
                autoplay: slider.getAttribute("data-autoplay"),
              },
            });
            instance.$mount(); // pass nothing
            slider.innerHTML = "";
            slider.appendChild(instance.$el);
            slider.classList.add("loaded");
          });
      }
    },
  },
  mounted() {
    this.initSlider();
  },
};
</script>

<style lang="scss" scoped>
.rsvi-tabs {
  &__head {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    margin-bottom: 16px;
    @media screen and (max-width: 1199px) {
      grid-gap: 16px;
    }
    @media screen and (max-width: 767px) {
      grid-gap: 8px;
    }
  }

  &__item {
    cursor: pointer;
    padding: 20px 16px;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f1f1f;
    height: 100%;
    transition: 0.3s;
    @media screen and (max-width: 1199px) {
      padding: 20px 8px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px;
      font-size: 12px;
      line-height: 16px;
    }

    &_active {
      height: calc(100% + 16px);
      background-color: #e5f4f8;
      border-color: #e5f4f8;
      border-radius: 8px 8px 0 0;
    }

    & svg {
      flex-shrink: 0;
    }
  }

  &__content {
    padding: 24px 16px;
    background-color: #e5f4f8;
  }
}
</style>
