<template>
  <div class="calc">
    <div class="calc__body">
      <div class="calc__title">
        Калькулятор расчета площади поверхности тела
      </div>
      <div class="calc__inputs">
        <div class="calc__input-container">
          <div class="calc__input-label">Масса тела, кг</div>
          <input
            type="number"
            class="calc__input"
            placeholder="кг"
            v-model="weight"
          />
        </div>
        <div class="calc__input-container">
          <div class="calc__input-label">Рост, см</div>
          <input
            type="number"
            class="calc__input"
            placeholder="см"
            v-model="height"
          />
        </div>
      </div>
      <div class="calc__button button button_pink" @click="calculate">Рассчитать</div>
    </div>
    <div class="calc__result">
      <div class="calc__result-row">
        <div class="calc__result-title">ППТ (BSA), м<sup>2</sup></div>
        <div class="calc__result-value">
          {{ result }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KoselugoCalc",
  data: () => ({
    weight: null,
    height: null,
    result: null,
  }),
  methods: {
    calculate() {
      if(!this.height && !this.weight) {
        return
      }
      this.result = (0.007184*Math.pow(parseInt(this.height), 0.725) * Math.pow(parseInt(this.weight), 0.425)).toFixed(2)
    }
  }
};
</script>

<style lang="scss" scoped>
.calc {
  &__body {
    background-color: #f8f8f8;
    padding: 32px;
    border: 1px solid #d2d2d2;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #830051;
  }

  &__inputs {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 125px 125px;
    column-gap: 32px;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 0 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-appearance: textfield;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #1f1f1f;
    background-color: #f8f8f8;
    border: 1px solid #d2d2d2;
    border-radius: 99px;
    &::placeholder,
    &::-ms-input-placeholder {
      color: #3c4242;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &-label {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
    }
  }

  &__button {
    width: fit-content;
    padding: 0 18px;
  }

  &__result {
    padding: 32px;
    background-color: #830051;

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-title {
      margin-right: 32px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
    }

    &-value {
      padding: 0 18px;
      width: fit-content;
      min-width: 132px;
      height: 40px;
      border-radius: 99px;
      background-color: #ebefee;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1f1f1f;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>