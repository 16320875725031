import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=11c440a8&scoped=true&"
import script from "./EventDetail.vue?vue&type=script&lang=js&"
export * from "./EventDetail.vue?vue&type=script&lang=js&"
import style0 from "./EventDetail.vue?vue&type=style&index=0&id=11c440a8&prod&lang=scss&scoped=true&"
import style1 from "./EventDetail.vue?vue&type=style&index=1&id=11c440a8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c440a8",
  null
  
)

export default component.exports